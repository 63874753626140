import { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.min.css";

import { SortOption } from '../../app/models/sortoption';
import { CsvRecords } from '../../app/models/csvrecords';

import { HomeStatistics } from './HomeStatistics';
import { HomeFilter } from './HomeFilter';
import HomeTable from './HomeTable';

export default function Home () {
  const dates = {
    from:  moment().subtract(1, 'months').startOf('month').toDate(),
    to: moment().subtract(1, 'months').endOf('month').toDate()
  }

  const [fromDate, setFromDate] = useState<Date>(dates.from);
  const [toDate, setToDate] = useState<Date>(dates.to);
  const [file, setFile] = useState<File|null>(null); 
  const [parsedCsvData, setParsedCsvData] = useState<[]>([]);
  const [checked, setChecked] = useState<string[]>([])
  const [records, setRecords] = useState<CsvRecords[]>([])
  const [sortOptions, setSortOptions] = useState<SortOption[]>([])

  const getCsvRecords = useCallback(() => {
    const activeSortOption = sortOptions.find(option => option.active);
    const records = parsedCsvData.map((item) => {
      const {"Date": date, "Largest Contentful Paint (ms)": lcp} = item;

      return {
        date,
        lcp: Number(lcp)
      } as CsvRecords
    }).filter(({ date }) => {
      const lcpDate = moment(date);
      return !checked.includes(date) && moment(lcpDate).isBetween(fromDate, toDate)
    });

    if (!activeSortOption) return records;
      
    const id = activeSortOption.id as keyof CsvRecords;
    const type = activeSortOption.type;
      
    return records.sort((a, b) => {
      const value1 = a[id];
      const value2 = b[id];
      const isNumber = typeof value1 === 'number';

      if (isNumber) {
        return (type === 'asc' ? Number(value1) - Number(value2) : Number(value2) - Number(value1));
      } else {
        return (a[id] < b[id] ? -1 : 1) * (type === 'asc' ? 1 : -1)
      }
    });

  }, [parsedCsvData, fromDate, toDate, checked, sortOptions])

  const onCheckHandler = useCallback((item: string): void => {
    let checkedUpdated: string[] = typeof item === 'undefined' || !item.length ? [] : [...checked, item];
    setChecked(checkedUpdated);
  }, [checked])

  const onSortHandler = (header: string) => {
    let prop = header as keyof CsvRecords;
    let updateSort = [...sortOptions].map((option) => {
      return {
        ...option,
        active: false
      } as SortOption
    })

    const index = sortOptions.findIndex(sort => sort.id === prop)
    const type = index !== -1 && sortOptions[index].type === 'asc' ? 'desc' : 'asc'

    if (index === -1) {
      updateSort = [...updateSort, { 
        id: prop, 
        type: type,
        active: true
      }]
    } else {
      updateSort[index].type = type;
      updateSort[index].active = true;
    }

    setSortOptions(updateSort)
  }

  useEffect(() => {
    const records = getCsvRecords();
    setRecords(records)
  }, [getCsvRecords, fromDate, toDate, checked])

  const handleFromDateChange = (date: Date) => {
    setChecked([]);
    setFromDate(date);

    if (moment(date).isAfter(moment(toDate))) {
      setToDate(moment(date).add(5, 'days').toDate())
    }
  }

  const handleToDateChange = (date: Date) => {
    setChecked([]);
    setToDate(date);

    if (moment(date).isBefore(moment(fromDate))) {
      setFromDate(moment(date).subtract(5, 'days').toDate())
    }
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = (event.target.files as FileList)[0];
    setChecked([]);
    setFile(file)
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    
    if (!file) return;    

    Papa.parse(file, {
      header: true,
      complete: (results: any) => {
        setParsedCsvData(results.data)
      }
    });
  }
 
  return (
    <>
      <div className='container-sm'>
        <div className="row">
          <div className="col">
            <div className='search-box mt-4'>
              <form onSubmit={handleSubmit}>
                <div className='form-group mb-3 row'>
                  <div className="col-6">
                    <DatePicker 
                      selected={fromDate} 
                      onChange={handleFromDateChange} 
                      className="form-control"
                      popperPlacement="bottom-start" 
                    />
                  </div>
                  <div className="col-6">
                    <DatePicker 
                      selected={toDate} 
                      onChange={handleToDateChange} 
                      className="form-control" 
                      popperPlacement="bottom-start"
                    />
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <input className="form-control" type="file" name="file" id="file" onChange={handleFileInputChange} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
            <HomeStatistics records={records} />
            <HomeTable records={records} checked={checked} sortOptions={sortOptions} onCheckHandler={onCheckHandler} onSortHandler={onSortHandler} />
            <HomeFilter checked={checked} onReset={onCheckHandler} />
          </div>
        </div>
      </div>
    </>

  )
}