import { useCallback, useEffect, useState } from 'react';
import { CsvRecords } from '../../app/models/csvrecords';
import confetti from 'canvas-confetti';

interface Props {
  records: CsvRecords[];
}

let myCanvas:any = null;
let myConfetti:any = null;

const createCanvas = (): void => {
  if (myConfetti) return;

  if (!myCanvas) {
    myCanvas = document.createElement('canvas');
    const confettiContainer = document.getElementById('confetti')
    confettiContainer?.append(myCanvas)
  }
  
  myConfetti = confetti.create(myCanvas, {
    resize: false,
    useWorker: false
  });
}

export function HomeStatistics ({ records }: Props) {
  const [average, setAverage] = useState<Number>(0)
  const [confettiActive, setConfettiActive] = useState<Boolean>(false)
    
  const getCssClasses = (): string => {
    const colorCssClass = `statistics--${getCurrentStatus()}`
    return `statistics ${colorCssClass} d-flex flex-column align-items-center mt-3 mb-3`
  }

  const getCurrentStatus = useCallback(() => {
    const colors:string[] = ['green', 'orange', 'red'];
    const threshold:number[] = [1200, 1666];
    const lastColorIndex:number = colors.length - 1;      
    const index:number = threshold.findIndex(value => average <= value);

    return index === -1 ? colors[lastColorIndex] : colors[index];
  }, [average]);

  const getAverage = useCallback(() => {
    const total = records.reduce((acc, cur: CsvRecords) => {

      if (!acc) {
        acc = 0;
      }
    
      acc += cur.lcp;
    
      return acc; 
    }, 0);

    return Number((total / records.length).toFixed(2));
  }, [records]);
  
  useEffect(() => {
    if (!records.length) return;

    createCanvas();

    const average = getAverage();
    const status = getCurrentStatus();

    setAverage(average)

    if (status !== 'green') {
      myConfetti.reset();

      if (confettiActive) {
        setConfettiActive(false);
      }
    } else {
      if (confettiActive) return;

      setConfettiActive(true);
      
      myConfetti({
        shapes: ['square'],
        particleCount: 100,
        spread: 90,
        origin: {
            y: (1),
            x: (0.5)
        }
      });
    }   
  }, [records.length, confettiActive, getAverage, getCurrentStatus] )

  if (!records.length) return <></>;

  return (
    <div className={getCssClasses()}>
      <div id='confetti' />
      <span>average lcp</span>
      <h2>{average.toString()} ms</h2>
    </div>
  )
}