interface Props {
  checked: string[];
  onReset: Function
}

export function HomeFilter ({checked, onReset}: Props) {
  const resetFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onReset();
  }

  if (!checked.length) return <></>

  return (
    <>
      <div className='filter d-flex align-items-center mt-3'>
        <div><button type="submit" className="btn btn-secondary" onClick={resetFilter}>Remove filter</button></div>
        <div>{checked.length} removed from results</div>
      </div>
    </>
  )
}