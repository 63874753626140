import { Routes, Route } from 'react-router-dom';

import Header from './app/layout/Header';

import Home from './features/home/Home';
import About from './features/about/About';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
      </Routes>
    </>    
  );
}

export default App;
