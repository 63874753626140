
import React from 'react';

import { CsvRecords } from '../../app/models/csvrecords';
import { SortOption } from '../../app/models/sortoption';

interface Props {
  records: CsvRecords[],
  checked: string[],
  sortOptions: SortOption[],
  onCheckHandler: Function,
  onSortHandler: Function
}

interface Header {
  date: string,
  lcp: string
}

interface TableHeader {
  id:  string,
  label: string
}

const headers = {
  date: "Date",
  lcp: "Largest Contentful Paint (ms)"
}

export default function HomeTable ({ records, checked, sortOptions, onCheckHandler, onSortHandler }: Props) {
  const tableHeader: TableHeader[] = (records && records.length ? Object.keys(records[0]) : []).map(header => {
    let key = header as keyof Header;

    return {
      id: key,
      label: headers[key]
    }
  });

  const getClassName = (tableHeader: TableHeader) => {
    const item = sortOptions.find(option => option.id === tableHeader.id)

    if (!item || (item && !item.active)) return 'sort-by';

    return item.type === 'asc' ? 'sort-by sort-by-asc' : 'sort-by sort-by-desc'
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    onCheckHandler(item)
  }

  const handleSortClick = (item: string) => {
    onSortHandler(item)
  }

  const isChecked = (record: CsvRecords) => checked.includes(record.date) ? true : false;

  return (
    <>
      {records && records.length ?
        <div className='table-wrapper'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">&nbsp;</th>
                {tableHeader.map((item, index) => (
                  <th key={index} scope="col">
                    <span className={getClassName(item)} onClick={() => handleSortClick(item.id)}>{ item.label }</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox" 
                      value={record.date}
                      onChange={handleCheck} 
                      checked={isChecked(record)} />
                  </td>
                  <td>{record.date}</td>
                  <td>{record.lcp}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      : ''}
    </>
  )
}